<template>
  <div class="login-container"></div>
</template>

<script>
import settings from "@/settings";
import { getOSToken } from "@/utils/auth"

export default {
  name: "Logout",
  components: {},
  data() {},
  created() {
    const query = this.$route.query;

    var os = getOSToken();
    var redirect = settings.logout_redirect;

    if (os === "iOS") {
      redirect = settings.logout_redirect_ios;
    }


    if (query) {
      window.location =
        settings.service_logout +
        "?logout_return=" +
        encodeURIComponent(redirect);
    }
  }
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
}
</style>